/* copy embedObjktViewSizes in mixins.scss */

.objktview-zipembed.objktview {
  position: relative;
  display: block;
  margin: 0 auto; 
  border : none;
  width: 100vw;
  height: 100vw;
}

.message {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 600px) {
  .objktview-zipembed.objktview {
    width: 550px;
    height: 550px;
  }
}

@media (min-width: 1024px) {
  .objktview-zipembed.objktview {
    min-height: 550px;
    min-width: 550px;
    width: calc(100vh - 280px);
    height: calc(100vh - 280px);
  }
}

a {
  color: var(--text-color);
  font-size: 16px;
}

a:hover {
  text-decoration: underline;
}