@import '../../styles/layout.scss';
@import '../../styles/variables.scss';

.container {
  position: relative;

  .number {
    position: absolute;
    bottom: 6px;
    right: 6px;
    padding: 4px;
    background-color: var(--text-color);
    color: var(--background-color);
    transition: background-color #{$theme-duration} ease-out,
      color #{$theme-duration} ease-out;
  }
}
