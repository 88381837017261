@import '../../styles/layout.scss';

.container {
  display: flex;
  flex-direction: column !important;

  .media,
  .info {
    width: 100%;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }

  .description {
    white-space: pre-wrap;
  }

  @include respond-to('tablet') {
    flex-direction: initial;

    .media,
    .info {
      width: 100%;
    }

    .media {
      min-height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      padding-left: 10px;

      .title,
      .description {
        margin-bottom: 20px;
      }

      .title {
        font-family: 'basier_circle_monoregular';
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}
