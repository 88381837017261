@import '../../../styles/layout.scss';

.viewer {
  width : 100%;
  height : 60vh; 
  display: block;
  margin: 0 auto; 
  border : none;

  @include respond-to('desktop') {
    width: 50% !important;
  }
}

.container {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

model-viewer {
  width: 100%;
  height: 60vh;
  margin: 0 auto;
}

.arButton {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: var(--text-color);
  background-color: transparent;
  border-radius: 4px;
  border: 2px solid var(--text-color);
  padding: 4px 8px;
  cursor: pointer;
}