@import '../../styles/layout.scss';

.container {
  position: relative;
  display: block;

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    background-color: var(--background-color);
    
    & iframe {
      width: 100vw;
      height: 100vh;
    }

    & model-viewer {
      width: 100vw;
      height: 100vh;
    }

    & img {
      max-height: 90vh;
    }
  }

  .icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    background-color: #000;
    border-radius: 1000px;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    svg {
      width: 12px;
      height: 12px;
      path {
        fill: white;
      }
    }
  }
  &:hover {
    .icon {
      opacity: 0.5;
    }
  }
}