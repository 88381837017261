.tag {
    display: inline-flex;
    color: var(--text-color);
    border-radius: 4px;
    padding: 4px 8px;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: bold;
  }

