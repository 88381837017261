@import '../../styles/variables.scss';

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .circle {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: inline-block;
    background-color: var(--text-color);
    width: 20px;
    height: 20px;
    border-radius: 1000px;
    transition: background-color #{$theme-duration} ease-out;
    animation: circle 1.5s linear infinite;
  }

  .message {
    margin-left: 10px;
  }
}

@keyframes circle {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
