@import '../../styles/variables.scss';

.identicon {
  svg {
    border-radius: 100%;
    padding: 1px;
    width: 127.5px;
    height: 127.5px;
    //border: 1px solid var(--text-color);
    transition: border-color #{$theme-duration} ease-out;

    path {
      stroke: var(--text-color);
      fill: var(--text-color);
      transition: all #{$theme-duration} ease-out;
    }
  }

  img {
    border-radius: 100%;
    padding: 1px;
    width: 127.5px;
    height: 127.5px;
    //border: 2px solid var(--text-color);
    transition: border-color #{$theme-duration} ease-out;
  }
}
