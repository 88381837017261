@import '../../../styles/layout.scss';
@import '../../../styles/variables.scss';

.container {
  width: 100%;

  .square {
    display: block;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--background-color);
    background-color: var(--text-color);
    transition: background-color #{$theme-duration} ease-out,
      color #{$theme-duration} ease-out;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
}
