@import '../../../styles/layout.scss';

.container {
  position: relative;
  display: block;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
  }

  iframe {
    border: none;

  }

  canvas{
    max-width: 100%;
    margin: 0 auto;
    height: auto!important;

  }
  
  .pdfNav {
    position: relative;
    margin: 6px;
    text-align: center;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 6px 12px;
    }
  }
}
