@import '../../../styles/variables.scss';
@import '../../../styles/layout.scss';

.container {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  word-break: break-word;

  @include respond-to('desktop') {
    width: 50%;
  }

  &.large {
    @include respond-to('desktop') {
      width: 55%;
    }
  }

  &.xlarge {
    @include respond-to('desktop') {
      width: 80%;
    }
  }
}
