@import '../../../styles/layout.scss';
@import '../../../styles/variables.scss';

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: 2;

  @supports (padding: unquote('max(0px)')) {
    padding-top: unquote('max(0px, env(safe-area-inset-top))');
    padding-bottom: unquote('max(0px, env(safe-area-inset-top))');
  }

  @include respond-to('tablet') {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .image {
    width: 50vh;
    max-width: 100%;
    margin: 0 auto;
    padding: 50px 0px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to('desktop') {
      width: 70vh;
      padding: 50px 20px;
    }

    > div {
      width: 100%;
    }
  }

  .info {
    width: 100%;
    padding: 20px;
    background-color: var(--background-color);
    color: var(--text-color);

    > div {
      margin: 0 auto;

      @include respond-to('desktop') {
        width: 1024px;
        max-width: 100%;
      }
    }

    .number {
      display: inline-block;
      padding: 4px;
      background-color: var(--text-color);
      color: var(--background-color);
      margin-bottom: 2rem;
    }

    .title {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .description {
      margin-bottom: 2rem;
    }

    .links {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 3rem;

      @include respond-to('tablet') {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;
      }

      .collect {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;
      }
    }
  }
}
