@import '../../../styles/layout.scss';
@import '../../../styles/mixins.scss';

.vector {
  @include embedObjktViewSizes;
}

.display {
  width : 100%;
  display: block;
  margin: 0 auto; 
  border : none;
}

.container {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &.interactive {
    pointer-events: all;
  }

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%) translateY(-50%);
    border: none;
    background: black;
  }
}
