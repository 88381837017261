@import '../../styles/variables.scss';

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tag {
    display: inline-flex;
    font-weight: bold;
    color: var(--text-color);
    margin-right: 12.5px;
    margin-bottom: 6px;
    text-transform: lowercase;
    font-size: 16px;
    transition: background-color #{$theme-duration} ease-out,
      color #{$theme-duration} ease-out;
  }
}
