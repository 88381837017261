@import '../../styles/layout.scss';
@import '../../styles/variables.scss';
.container {
  position: relative;

  .close {
    position: fixed;
    top: 0;
    right: 0;
    padding: 1rem;
    color: var(--text-color);
    font-weight: bold;
    z-index: 2;
    cursor: pointer;

    @include respond-to('tablet') {
      padding: 2rem;
    }
  }

  .content {
    margin: 4rem 0;
  }

  .block {
    margin: 4rem 0;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3333;
  display: flex;
  align-items: center;
  justify-content: center;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    // opacity: 0.9;
    background-color: var(--background-color);
    z-index: 1;
  }
}
