@import '../../../styles/layout.scss';

.container {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video {
  position: relative;
  width: 100%;
  height: auto;
  outline: 0px;
}