@import '../../styles/variables.scss';

.menu {
  margin-left: 6px;
  // border: 1px dashed black;

  svg {
    display: block;
    width: 30px;
    height: 30px;

    line {
      stroke: var(--text-color);
      transition: stroke #{$theme-duration} ease-out;
    }
  }
}
