@import 'variables.scss';
@import 'typography.scss';

html {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Mono', 'Courier New', 'Courier', monospace;
  // font-family: 'OCR A Std', monospace; // README: crzypatchwork, this font doesn't look great.
  // font-family: 'Courier New', 'Courier', monospace; // README: old font
  overflow-x: hidden;
  color: var(--text-color);
  background-color: var(--background-color);
  transition: background-color #{$theme-duration} ease-out,
    color #{$theme-duration} ease-out;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  font-family: 'IBM Plex Mono', 'Courier New', 'Courier', monospace;
  // font-family: 'OCR A Std', monospace;// README: crzypatchwork, this font doesn't look great.
  // font-family: 'Courier New', 'Courier', monospace;// README: oldfont
}