.tag-view .vector {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%) translateY(-50%);
  border: none;
  min-height: 0;
  min-width: 0;
}

.objktview .vector-container {
  pointer-events: all;
}