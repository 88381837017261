// mixin to reset <button>
@import './layout.scss';

@mixin resetButton() {
  border: 0;
  padding: 0;
  user-select: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;

  &:focus {
    outline: 0;
  }
}

@mixin embedObjktViewSizes() {
  position: relative;
  display: block;
  margin: 0 auto; 
  border : none;
  width: 100vw;
  height: 100vw;

  @include respond-to('tablet') {
    width: 550px;
    height: 550px;
  }

  @include respond-to('desktop') {
    min-height: 550px;
    min-width: 550px;
    width: calc(100vh - 280px);
    height: calc(100vh - 280px);
  }
}

// mixins for touch hover state on press
@mixin media-pointer {
  @media (pointer: fine) {
    @content;
  }
}
@mixin media-touch {
  @media (any-pointer: coarse) {
    @content;
  }
}

@mixin hover {
  @include media-touch {
    &:active {
      @content;
    }
  }
  @include media-pointer {
    &:hover {
      @content;
    }
  }
}
