:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --border-color: rgba(0, 0, 0, 0.1);
  --shadow-color: rgba(0, 0, 0, 0.2);
}

$theme-duration: 0.5s;
$mobile-padding: 1.25rem;
$letter-spacing: 0.015em;
