@import '../../styles/layout.scss';
@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.profile {
  display: flex;

  .info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    p {
      margin-bottom: 8px;
    }
  }
}

.menu {
  padding: 6px 0;
  border-bottom: 1px solid var(--border-color);
  transition: border-color #{$theme-duration} ease-out;

  & > * {
    margin-right: 1em;
  }
}

.container {
  .number {
    position: absolute;
    bottom: 6px;
    right: 6px;
    padding: 4px;
    background-color: var(--text-color);
    color: var(--background-color);
    transition: background-color #{$theme-duration} ease-out,
      color #{$theme-duration} ease-out;
  }
}

.filter {
  display: inline;
  float: right;

  svg {
    position: relative;
    top: 2px;
    left: -2px;
    width: 20px;
    height: 20px;
    fill: var(--background-color);
  }
}

.card {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 30%;  
  min-height: 150px;
  
  background-color: var(--text-color);
  transition: background-color #{$theme-duration} ease-out,
  color #{$theme-duration} ease-out, opacity ease-out #{$theme-duration};
  z-index: 100;
  opacity: 0;
}

.cardContainer:hover .card {
  opacity: 1;
}

.cardText {
  position: relative;
  color: var(--background-color);
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;

  overflow: hidden;
  white-space: nowrap;
}

.cardTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cardCollect {
  @include resetButton;
  position: absolute;
  bottom: 0;
  right: 0;
  border-right: 2px solid var(--background-color);
  border-bottom: 2px solid var(--background-color);
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;

  .cardCollectPrice {
    color: var(--background-color);
  }
}

.tag {
  display: inline-flex;
  color: var(--text-color);
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  text-transform: lowercase;
  font-size: 15px;
  transition: background-color #{$theme-duration} ease-out,
    color #{$theme-duration} ease-out;
}

.tag:hover {
  text-decoration: underline;
}

span[data-tooltip].top {
  &:before,
  &:after {
    transform: translate(-50%, 10px);
  }

  &:hover:after,
  &:hover:before {
    transform: translate(-50%, 0px);
  }
}

span[data-tooltip] {
  position: relative;

  &:after,
  &:before {
    position: absolute;
    visibility: hidden;
    opacity: 1;
    transition: transform 200ms ease, opacity 200ms;
    z-index: 99;
  }

  &:before {
    content: attr(data-tooltip);
    background-color: var(--text-color);
    color: var(--background-color);
    font-size: 12px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 10px;
    white-space: nowrap;
    text-decoration: none;
    letter-spacing: 1px;
  }

  &:after {
    width: 0;
    height: 0;
    left: 50%;
    border: 6px solid transparent;
    content: '';
  }

  &:hover:after,
  &:hover:before {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0px);
  }
}

span[data-tooltip][data-position='top']:before {
  bottom: 100%;
  left: 50%;
  margin-bottom: 9px;
}

span[data-tooltip][data-position='top']:after {
  border-top-color: var(--text-color);
  border-bottom: none;
  bottom: 101%;
  margin-bottom: 4px;
}