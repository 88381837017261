@import '../../styles/variables.scss';
@import '../../styles/layout.scss';

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--text-color);
  color: var(--background-color);
  transition: background-color #{$theme-duration} ease-out,
    color #{$theme-duration} ease-out;
  padding: $mobile-padding;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > * {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .copy,
  .buttons,
  .warning {
    width: 100%;
  }

  .copy {
    & > * {
      margin-right: 1rem;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 25%;

    & > * {
      margin-left: 1rem;
    }
  }
  .warning {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 1em;
  }
}
