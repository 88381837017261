.container {
  svg {
    path {
      fill: var(--text-color);
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
