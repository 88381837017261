.grid {
  display: flex;
  margin-left: -5px;
  width: auto;
}

.column {
  padding-left: 5px;
  background-clip: padding-box;

  & > * {
    position: relative;
    display: block;
    margin-bottom: 5px;
  }
}
