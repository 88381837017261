@import '../../styles/variables.scss';

.container {
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--background-color);
  transition: background-color #{$theme-duration} ease-out,
    color #{$theme-duration} ease-out;
  z-index: 1000;
  cursor: pointer;
}
