@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;

  .owner {
    display: flex;
    align-items: center;
    color: var(--text-color);
  }
}
