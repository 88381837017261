html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

strong,
b,
mark {
  font-style: inherit;
  font-weight: bold;
}

em,
i,
cite,
q,
address,
dfn,
var {
  font-style: italic;
  font-weight: inherit;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

ins {
  border-bottom: 1px solid;
}

a,
u,
ins {
  text-decoration: none;
}

del,
s {
  text-decoration: line-through;
}

pre,
code,
samp,
kbd {
  font-family: monospace;
}

small {
  font-size: 0.75em;
}

img {
  border: none;
  font-style: italic;
}

input,
select,
textarea {
  font: inherit;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}
